import React from "react"

import "./ogado-movie.scss"

const OgadoMovie = ({ lang, movie }) => (
  <div className="ogado__movie">
    <div className="ogado__movie-header">
      <h3 className="ogado__movie-header-title">{movie.title}</h3>
      <span className="ogado__movie-header-subtitle">
        <span className="ogado__movie-header-subtitle__added">
          {lang === "it" ? "Aggiunto" : "Added"} {movie.timesAdded}{" "}
          {movie.timesAdded === 1
            ? lang === "it"
              ? "volta"
              : "time"
            : lang === "it"
            ? "volte"
            : "times"}
        </span>
      </span>
    </div>

    <div className="row content__content-gallery">
      <div
        style={{
          backgroundImage: `url('https://image.tmdb.org/t/p/w500${movie.poster_path}')`,
        }}
        className="content__content-image"
      >
        <div className="content__content-image-description">
          <span className="genre">
            <strong>{movie.genres?.length ? movie.genres[0]?.name : ""}</strong>
          </span>
          <span className="year">{movie.release_date.substring(0, 4)}</span>
        </div>
      </div>
      {movie.images?.posters?.map((image, index) => (
        <img
          key={image.file_path}
          src={`https://image.tmdb.org/t/p/w500${image.file_path}`}
          className="content__content-image"
          alt={`${movie.title} immagine ${index}`}
        ></img>
      ))}
    </div>

    <span style={{ marginTop: '2rem', paddingLeft: "10px" }}>
      <strong>{lang === 'it' ? 'Trama' : 'Overview'}</strong>
    </span>
    <p className="content__content-overview">{movie.overview}</p>

    <span style={{ paddingLeft: "20px" }}>
      <strong>{lang === "it" ? "Disponibile su" : "Available on"}</strong>
    </span>

    <div className="content__platforms-list" style={{ overflowX: "auto" }}>
      {movie?.platforms.map(platform => (
        <a
          href={platform.link}
          target="_blank"
          rel="noopener noreferrer"
          className="content__platforms-platform"
        >
          <div style={{ backgroundImage: `url(${platform.logo.url})` }}></div>
        </a>
      ))}
    </div>

    <span style={{ marginTop: '2rem', paddingLeft: "20px" }}>
      <strong>Cast</strong>
    </span>

    <div className="row ogado__movie-actors">
      {movie.credits?.cast?.slice(0, 15).map((actor, index) => (
        <div
          key={actor.profile_path}
          style={{
            backgroundImage: `url('https://image.tmdb.org/t/p/w500${actor.profile_path}')`,
          }}
          className="ogado__movie-actors-actor"
        >
          <div className="content__content-image-description">
            <span className="genre">
              <strong>{actor.character}</strong>
            </span>
            <span className="year">{actor.name}</span>
          </div>
        </div>
      ))}
    </div>

    <span style={{ marginTop: "2rem", paddingLeft: "20px" }}>
      <strong>{lang === "it" ? "Regista" : "Director"}</strong>
    </span>

    <div className="row ogado__movie-actors">
      {movie.credits?.crew
        ?.filter(cast => cast.job === "Director")
        .map((actor, index) => (
          <div
            key={actor.profile_path}
            style={{
              backgroundImage: `url('https://image.tmdb.org/t/p/w500${actor.profile_path}')`,
            }}
            className="ogado__movie-actors-actor"
          >
            <div className="content__content-image-description">
              <span className="genre">
                <strong>{lang === "it" ? "Regista" : "Director"}</strong>
              </span>
              <span className="year">{actor.name}</span>
            </div>
          </div>
        ))}
    </div>

    <span style={{ marginTop: '2rem', paddingLeft: "20px" }}>
      <strong>{lang === "it" ? "Video" : "Videos"}</strong>
    </span>

    <div className="ogado__movie-videos">
      {movie.videos?.results?.map(video => (
        <iframe
          key={video.key}
          className="ogado__movie-videos-video"
          src={`https://www.youtube.com/embed/${video.key}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ))}
    </div>
  </div>
)

export default OgadoMovie
