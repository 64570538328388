import React from "react"
import firebase from "gatsby-plugin-firebase"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/content/content.scss"
import OgadoMovie from "../../components/ogado-movie/ogado-movie"
import Header from "../../components/header/header"
import { NewsletterBox } from "../../components/newsletter-box/newsletter-box"

export const ItalianMovieTemplate = ({ data }) => {
  if (!!firebase.analytics && !!firebase.performance) {
    firebase.analytics()
    firebase.performance()
  }

  const movie = data.italianMovie

  return (
    <Layout>
      <SEO
        title={movie.title}
        image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
        description={`Aggiungi ${movie.title} alla tua lista su Ogado e non perderti mai nessuna novità`}
        lang="it"
        keywords={[
          "ogado",
          "intrattenimento",
          "digitale",
          "film",
          movie.title,
          ...movie.platforms.map(platform => platform.name),
          ...movie.genres.map(genre => genre.name),
          ...movie.credits.cast.map(actor => actor.name),
        ]}
      />
      <Header lang="it" />
      <OgadoMovie lang="it" movie={movie}></OgadoMovie>
    </Layout>
  )
}

export default ItalianMovieTemplate

export const pageQuery = graphql`
  query ItalianMovieById($id: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    italianMovie(id: { eq: $id }) {
      adult
      backdrop_path
      genres {
        name
      }
      images {
        backdrops {
          file_path
        }
        posters {
          file_path
        }
      }
      overview
      platforms {
        link
        name
        logo {
          url
        }
      }
      poster_path
      release_date
      runtime
      timesAdded
      title
      videos {
        results {
          key
        }
      }
      credits {
        cast {
          character
          name
          profile_path
        }
        crew {
          job
          name
          profile_path
        }
      }
    }
  }
`
