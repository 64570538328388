import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import "./header.scss"

const Header = ({ lang = "it" }) => (
  <StaticQuery
    query={graphql`
      query HeaderImagesQuery {
        logo: file(relativePath: { eq: "logo_text.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <header
        className="ogado__header"
        style={{
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <Link to={lang === "it" ? "/" : "/en"}>
            <Img
              style={{ width: `300px` }}
              fluid={data.logo.childImageSharp.fluid}
              alt="Ogado logo"
            />
          </Link>
        </div>
        <div className="ogado__header-search">
          <input
            type="text"
            className="st-default-search-input"
            placeholder={lang === "it" ? "Cerca" : "Search"}
          />
        </div>
      </header>
    )}
  />
)

export default Header
